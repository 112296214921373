<template>
    <div class="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-32 lg:px-32">
        <div class="h-28 w-28 bg-white rounded-full shadow-md mx-auto p-5 z-10">
            <img src="@/assets/images/user.svg" />
        </div>

        <form class="rounded-xl shadow-md bg-white p-5 space-y-7 pt-20 -mt-12" @submit="submitGantiProfil()">
            <custom-input id="nama_lengkap" type="text" label="Nama Lengkap" v-model="mainForm.nama_lengkap" :errorMessage="errorForm.nama_lengkap"/>
            <custom-input id="email" type="email" label="Email" v-model="mainForm.email" :errorMessage="errorForm.email"/>
            <custom-input id="wa" type="text" label="Nomor WA" v-model="mainForm.wa" :errorMessage="errorForm.wa"/>
            <div>
                <button type="submit"
                @click="submitGantiProfil()" 
                :disabled="isLoading"
                class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-lg rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                        <svg class="h-5 w-5 text-blue-500 group-hover:text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                        </svg>
                    </span>
                    Simpan Profil
                </button>
            </div>
        </form>
        <loading-modal :isShow="isLoading" />
    </div>
</template>
<script>
    import CustomInput from '../../components/CustomInput.vue';
	import LoadingModal from '../../components/LoadingModal.vue';
    import {showSuccessNotif, showErrorNotif, extractValidationError, errorHandler} from '../../helpers/Tools';
    
    export default {
        name: 'ProfilUbah',
        components : {
			CustomInput, LoadingModal
        },
        data () {
            return {
				isLoading : false,
                mainForm: {
                    nama_lengkap: '',
                    email       : '',
                    wa          : '',
                },
                errorForm : {
                    nama_lengkap: '',
                    email       : '',
                    wa          : '',
                },
            }
        },

        mounted() {
            this.getUserData();
		},

        methods : {
            async getUserData () {
                let res = await this.$store.dispatch("getUserData");
                let result = res.data;
                let user = result.data.user;
                this.mainForm = {
                    nama_lengkap: user.nama_lengkap,
                    email       : user.email,
                    wa          : user.wa
                }
            },
			async submitGantiProfil () {
                this.isLoading = true;

                try {
                    this.errorForm = {
                        nama_lengkap: '',
                        email       : '',
					};

					let res = await this.$store.dispatch("postGantiProfil", this.mainForm);
					let result = res.data;
					this.isLoading = false;
					if(result.status == 'success') {
                        showSuccessNotif(this, result.message);
                        var userData            = result.data
                        var userDataGetter      = this.$store.getters.userLogin
                            userDataGetter.user = userData
						this.$store.dispatch('actionLoginUser', userDataGetter)
					} else {
                        this.errorForm = extractValidationError(this.errorForm, result.data);
					}

                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },
        }
    }
</script>
